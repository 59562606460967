import React, { useEffect, useState, useRef } from "react";
import produce from "immer";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";

import {
  Button,
  Box,
  ChevronLeftIcon,
  ChevronRightIcon,
  Checkbox,
  CloseIcon,
  Flex,
  HStack,
  Hidden,
  Pressable,
  VStack,
  Text,
  Center,
} from "native-base";
import NzHeader from "../../components/NzComponents/NzHeader";

import {
  Nz1024,
  NzBannerCard,
  NzButton,
  NzFlexWrap,
  NzHeading,
  NzLabel,
} from "../../components/NzComponents";
import {
  DownArrow2,
  BadgeNew,
  BadgeVerified,
} from "../../components/Icons/NzIcons";
import { getLFSV } from "../../actions";

import { lastDayThisMonthString } from "../../js/util";
const LFsvReport = (props) => {
  const auth = useSelector((state) => state.auth);
  const fsv = useSelector((state) => state.lfsv);

  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const [nextPage, setNextPage] = useState(false);
  const [direction, setDirection] = useState(false);
  const [data, setData] = useState();
  const [selectAll, setSelectAll] = useState(false);
  const [selected, setSelected] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (auth.c1 === "va") {
      dispatch(
        getLFSV({
          li: 20,
          desc: true,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (fsv.lastKey) {
      setNextPage(true);
    } else {
      setNextPage(false);
    }
  }, [fsv]);

  useEffect(() => {
    let _fsv;
    if (direction === "down") {
      _fsv = fsv.Items.reverse();
    } else {
      _fsv = fsv.Items;
    }
    setData(_fsv);
    setTotal(
      fsv.Items.reduce(function (prev, current) {
        return prev + current.n2;
      }, 0)
    );
  }, [fsv]);

  return (
    <>
      <Nz1024 bg="#efefe8">
        <NzBannerCard>
          <NzHeading>FSV Report</NzHeading>
          <NzLabel>
            {fsv.Items.length +
              " - Items Listed in this page\n\nLast Month FSV - " +
              total +
              " ( Rs." +
              total * 0.75 +
              ") - in this page"}
          </NzLabel>
        </NzBannerCard>
        <NzBannerCard>
          <Pagination />
          <HStack
            bg="#efefe8"
            py="3"
            w={{
              base: "90%",
              lg: "999px",
            }}
            justifyContent="space-around"
            alignItems="flex-start"
            shadow={4}
          >
            <Box>
              <Text pl="4" pr="1" bold>
                Mobile Number
              </Text>
            </Box>
            <Box width="20%">
              <Text pl="4" pr="1" bold>
                FSV Pending
              </Text>
            </Box>
            <Box width="20%">
              <Text pl="4" pr="1" bold>
                Total FSV
              </Text>
            </Box>
            <Box width="20%">
              <Text pl="4" pr="1" bold>
                Redeemed Invoices
              </Text>
            </Box>
          </HStack>

          <VStack
            w={{
              base: "90%",
              lg: "999px",
            }}
            justifyContent="space-around"
            alignItems="flex-start"
            shadow={8}
          >
            {data &&
              data.map((item, i) => {
                return (
                  <Item
                    el={item}
                    i={i}
                    onCheck={(checked) => {
                      select(checked, item);
                    }}
                    toggle={selectAll}
                  />
                );
              })}
          </VStack>
          <Pagination />
        </NzBannerCard>
      </Nz1024>
    </>
  );

  function select(checked, item) {
    if (checked) {
      //console.log('checked');
      setSelected(
        produce(selected, (draft) => {
          draft.push(item);
        })
      );
    } else {
      //console.log('not checked');
      setSelected(
        produce(selected, (draft) => {
          draft.splice(
            draft.findIndex((el) => el.i === item.i),
            1
          );
        })
      );
    }
  }

  function Pagination() {
    return (
      <HStack
        px="1"
        my="2"
        w={{
          base: "90%",
          lg: "999px",
        }}
        direction="row-reverse"
      >
        <HStack alignItems="center" space="4">
          <Button
            endIcon={<ChevronLeftIcon />}
            isDisabled={pageNumber === 1}
            variant="subtle"
            onPress={() => {
              dispatch(
                getLFSV({
                  li: 20,
                  la: { app: 21, lid: data[0].lid, n2: data[0].n2 },
                })
              );
              setDirection("down");
              setPageNumber(pageNumber - 1);
              setSelectAll(false);
            }}
          ></Button>
          <Box>{pageNumber}</Box>
          <Button
            endIcon={<ChevronRightIcon />}
            isDisabled={!nextPage}
            variant="subtle"
            onPress={() => {
              dispatch(
                getLFSV({
                  li: 20,
                  la:
                    direction === "down"
                      ? { app: 21, lid: data[20 - 1].lid, n2: data[20 - 1].n2 }
                      : fsv.lastKey,
                  desc: true,
                })
              );
              setDirection("up");
              setPageNumber(pageNumber + 1);
              setSelectAll(false);
            }}
          ></Button>
        </HStack>
      </HStack>
    );
  }
};

function Item({ el, i, onCheck, toggle }) {
  const [selected, setSelected] = useState(false);
  useEffect(() => {
    setSelected(toggle);
  }, [toggle]);
  return (
    <HStack width={"100%"} bg={i % 2 === 0 ? "white" : "#efefe8"}>
      <Box borderBottomColor="coolGray.200" borderBottomWidth="1" p="4">
        <Checkbox
          isChecked={selected}
          onChange={() => {
            selected ? setSelected(false) : setSelected(true);
            selected ? onCheck(false) : onCheck(true);
          }}
        />
      </Box>
      <Box
        borderBottomColor="coolGray.200"
        borderBottomWidth="1"
        pl="4"
        width="30%"
      >
        <Text px="1" my="4">
          {el.lid}
        </Text>
      </Box>
      <Box
        borderBottomColor="coolGray.200"
        borderBottomWidth="1"
        pl="4"
        width="20%"
      >
        <Text px="1" my="4">
          {el.n2}
        </Text>
      </Box>
      <Box
        borderBottomColor="coolGray.200"
        borderBottomWidth="1"
        pl="4"
        width="20%"
      >
        <Text px="1" my="4">
          {el.cu && el.cu[lastDayThisMonthString()]
            ? el.cu[lastDayThisMonthString()].fsv
            : ""}
        </Text>
      </Box>
      <Box
        borderBottomColor="coolGray.200"
        borderBottomWidth="1"
        pl="4"
        width="30%"
      >
        <Text px="1" my="4">
          {el.cu && el.cu[lastDayThisMonthString()]
            ? el.cu[lastDayThisMonthString()].usg.toString()
            : ""}
        </Text>
      </Box>
    </HStack>
  );
}

export default LFsvReport;
