import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { NativeBaseProvider, extendTheme, Center } from "native-base";

//import App from "./App";
import Routes from "./routes";

//import reportWebVitals from "./reportWebVitals";
import getStore from "./store";
const store = getStore();

const theme = extendTheme({
  colors: {
    // Add new color
    primary: {
      10: "#f2f2f2",
      11: "#8dc63f",
      12: "#d7df23",
      13: "#1a3966",
      14: "#edf4df",
      15: "#92c452",
      16: "#93ba41",
      17: "#93ba42",
      40: "#FFFFFF",
      45: "#e4f3e1",
      50: "#fef2f2",
      100: "#fee2e2",
      200: "#fecaca",
      300: "#fca5a5",
      400: "#f87171",
      500: "#dc2626",
      600: "#dc2626",
      700: "#b91c1c",
      800: "#991b1b",
      900: "#7f1d1d",
      1000: "#f87171",
    },
    secondary: {
      10: "#fcf0b6",
      20: "#deb23e",
      50: "#fffbeb",
      100: "#fef3c7",
      200: "#fde68a",
      300: "#fcd34d",
      400: "#fbbf24",
      500: "#f59e0b",
      600: "#d97706",
      700: "#b45309",
      800: "#92400e",
      900: "#78350f",
    },
  },
  components: {
    // Input: {
    //   // Can simply pass default props to change default behaviour of components.
    //   baseStyle: ({ colorMode, colorScheme }) => {
    //     return {
    //       bg: colorMode === "dark" ? "red.300" : `purple.500`,
    //       //ABOVE BG is not WORKING FOR SOME REASON...
    //       color: colorMode === "dark" ? "red.300" : "purple.300",
    //       fontWeight: "normal",
    //       mx: 4,
    //       my: 2,
    //       rounded: "sm",
    //     };
    //   },
    //   defaultProps: {},
    // },
    // Button: {
    //   // Can simply pass default props to change default behaviour of components.
    //   baseStyle: {
    //     rounded: 'md',
    //     colorScheme:'purple.700'
    //   },
    //   defaultProps: {
    //     colorScheme: 'red',
    //     color:'red'
    //   },
    // },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <NativeBaseProvider theme={theme}>
        <Routes />
      </NativeBaseProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
