export function capitalizeFirstLetter(string) {
  return string.replace(/^./, string[0].toUpperCase());
}

export function isPositiveNumber(s) {
  if (!isNaN(s) && s !== "" && s > 0) return true;
  else return false;
}

export function upperCamelCase(s) {
  if (s) {
    return s.replace(/\w+/g, function (w) {
      return w[0].toUpperCase() + w.slice(1).toLowerCase();
    });
  } else return s;
}

export function getFieldDistinctFromItems(items, fieldName) {
  var lookup = {};
  var result = [];

  for (var item, i = 0; (item = items[i++]); ) {
    var name = item[fieldName];

    if (!(name in lookup)) {
      lookup[name] = 1;
      result.push(name);
    }
  }
  return result;
}

export function lastDayThisMonthString() {
  const mn = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var m = new Date().getMonth();
  var y = new Date().getFullYear();
  var lastDayThisMonth = new Date(y, m + 1, 0);
  // var lastDayThisMonth = new Date(y, m + 1, 0, 23, 59, 59, 0);
  // //console.log(lastDayThisMonth); // last day this Month
  return lastDayThisMonth.getDate() + "-" + mn[m] + "-" + y;
}

export function lastDayMonthString(date) {
  const mn = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var m = new Date(date).getMonth();
  var y = new Date(date).getFullYear();
  var lastDayMonthString = new Date(y, m + 1, 0);
  // var lastDayThisMonth = new Date(y, m + 1, 0, 23, 59, 59, 0);
  // //console.log(lastDayThisMonth); // last day this Month
  return lastDayMonthString.getDate() + "-" + mn[m] + "-" + y;
}
