import { Center, Heading, Text, VStack } from "native-base";
import React from "react";

function Csr() {
  return (
    <section id="about">
      <Center bg="#00000005" py="12">
        <VStack
          w={{
            base: "90%",
            md: "80%",
          }}
          alignItems="center"
          space="8"
          my="4"
        >
          <Heading textAlign="center" color="primary.1000">
            About Kairali Super Store
          </Heading>
          <VStack space="4" alignItems="center">
            <Text fontSize="md" textAlign="center">
              We started 10 years back and we still stick to our ethics to
              provide value for money for our products
            </Text>
            <Text fontSize="md" textAlign="center">
              <strong>kairali super app</strong> allows you to shopping for
              groceries, Vegetables, fruits, dairy products and many more from
              us while sitting at home. No more waiting at shops, getting stuck
              in traffic jams, no worry for parking, no need to carrying heavy
              bags – get everything you need, when you need
            </Text>
          </VStack>
        </VStack>
      </Center>
    </section>
  );
}
export default Csr;
