const defaultState = {
  Count: 0,
  hasErrored: false,
  isLoading: false,
  Items: [],
  userUpdated: false,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case "GET_FSV_SUCCESS":
      //return action.payload;
      return Object.assign({}, state, {
        Items: action.payload.Items,
        lastKey: action.payload.LastEvaluatedKey,
        isLoading: false,
      });
    case "RESET":
      return defaultState;
    default:
      return state;
  }
}
