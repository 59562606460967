import Api from "../js/api";

let url = process.env.REACT_APP_OPT_SOCIETY_URL;

export const refreshPage = () => {
  return {
    type: "REFRESH_PAGE",
  };
};

export const reEnter = (payload) => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  localStorage.setItem("user", JSON.stringify(payload));
  return {
    type: "REENTER",
    payload: payload,
  };
};

// ------------loginTable---------------------
export const login = (payload) => {
  console.log(payload);
  return (dispatch) => {
    return Api.login(payload)
      .then((response) => {
        if (response.err) {
          //err property is passed back from service, when unauthenticated
          dispatch(loginFail(response.err));
        } else {
          dispatch(loginSuccess());
          dispatch(reEnter(response));
        }
      })
      .catch((err) => {
        dispatch(loginFail("networkerror"));
      });
  };
};

export function loginFail(errorString) {
  return {
    type: "LOGIN_FAIL",
    status: errorString,
  };
}

export const loginSuccess = (payload) => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "LOGIN_SUCCESS",
    payload: payload,
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch(logoutAuth());
    dispatch(reset());
  };
};

export const reset = () => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "RESET",
  };
};

//-------------Log out ----------------------------
export const logoutAuth = () => {
  return {
    type: "LOGOUT",
  };
};

// -----------------------GetCoupon------------------------

export const getCouponsAlloted = (pickDate) => {
  return (dispatch) => {
    return (
      Api.getCouponsAlloted(
        "ksc_" +
          pickDate.getDate() +
          "_" +
          (pickDate.getMonth() + 1) +
          "_" +
          pickDate.getFullYear()
      )
        // .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          dispatch(getCouponsSuccess(responseJson));
        })
        .catch((err) => {
          dispatch(couponsHasErrored(true));
        })
    );
  };
};

export const getCoupons = (id) => {
  return (dispatch) => {
    return (
      Api.getCoupons(id)
        // .then((response) => response.json())
        .then((responseJson) => {
          //console.log(responseJson);
          dispatch(getCouponsSuccess(responseJson));
        })
        .catch((err) => {
          dispatch(couponsHasErrored(true));
        })
    );
  };
};

export const getTransactions = (id) => {
  return (dispatch) => {
    return (
      Api.getTransactions(id)
        // .then((response) => response.json())
        .then((responseJson) => {
          //console.log(responseJson);
          dispatch(getTransactionSuccess(responseJson));
        })
        .catch((err) => {
          dispatch(transactionsHasErrored(true));
        })
    );
  };
};

export const getFSV = (om) => {
  return (dispatch) => {
    return (
      Api.getFSV(om)
        // .then((response) => response.json())
        .then((responseJson) => {
          //console.log(responseJson);
          dispatch(getFSVSuccess(responseJson));
        })
        .catch((err) => {
          dispatch(couponsHasErrored(true));
        })
    );
  };
};

export const getLFSV = (om, redeemedOnly) => {
  return (dispatch) => {
    return (
      Api.getLFSV(om, redeemedOnly)
        // .then((response) => response.json())
        .then((responseJson) => {
          //console.log(responseJson);
          dispatch(getLFSVSuccess(responseJson));
        })
        .catch((err) => {
          dispatch(couponsHasErrored(true));
        })
    );
  };
};

export const redeemCoupons = (om) => {
  return (dispatch) => {
    return (
      Api.redeemCoupons(om)
        // .then((response) => response.json())
        .then((responseJson) => {
          //console.log(responseJson);
          dispatch(redeemCouponsSuccess(responseJson));
        })
        .catch((err) => {
          dispatch(redeemCouponsFailed());
        })
    );
  };
};

export function couponsHasErrored(bool) {
  return {
    type: "ITEMS_HAS_ERRORED",
    hasErrored: bool,
  };
}

export function transactionsHasErrored(bool) {
  return {
    type: "TRANSACTION_HAS_ERRORED",
    hasErrored: bool,
  };
}

export function redeemCouponsFailed() {
  return {
    type: "REDEEM_COUPON_FAIL",
  };
}

export const getCouponsSuccess = (payload) => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "GET_COUPON_SUCCESS",
    payload: payload,
  };
};

export const getTransactionSuccess = (payload) => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "GET_TRANSACTION_SUCCESS",
    payload: payload,
  };
};

export const getFSVSuccess = (payload) => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "GET_FSV_SUCCESS",
    payload: payload,
  };
};

export const getLFSVSuccess = (payload) => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "GET_LFSV_SUCCESS",
    payload: payload,
  };
};

export const redeemCouponsSuccess = (payload) => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "REDEEM_COUPON_SUCCESS",
    payload: payload,
  };
};
