import { combineReducers } from "redux";
import AuthReducer from "./reducer-Auth";
import CouponsReducer from "./reducer-Coupons";
import FsvReducer from "./reducer-Fsv";
import LFsvReducer from "./reducer-LFsv";
import TransactionReducer from "./reducer-Transaction";

export default function getRootReducer() {
  return combineReducers({
    auth: AuthReducer,
    coupons: CouponsReducer,
    fsv: FsvReducer,
    lfsv: LFsvReducer,
    transactions: TransactionReducer,
  });
}
