import React from "react";
import { useSelector } from "react-redux";
import {
  Menu,
  HamburgerIcon,
  HStack,
  Pressable,
  Text,
  VStack,
} from "native-base";
// import "./style.css";
import Logo from "./Logo.jpg";
import Nz1024 from "../nz1024";

const NzHeader = (props) => {
  const auth = useSelector((state) => state.auth);
  return (
    <Nz1024 borderBottomWidth="1" borderBottomColor="#dadce0">
      <VStack w={"100%"}>
        <HStack justifyContent="space-between" alignItems="center">
          {/*<Hidden till="lg">
              <Text onClick={() => props.history.push("/logout")}>Logout</Text>
            </Hidden>
            <Hidden from="lg">*/}
          <HStack alignItems="center" space="3">
            <Menu
              w="190"
              trigger={(triggerProps) => {
                return (
                  <Pressable
                    accessibilityLabel="More options menu"
                    {...triggerProps}
                    alignSelf="center"
                  >
                    <HamburgerIcon />
                  </Pressable>
                );
              }}
            >
              {/* <Menu.Item isDisabled>
                <Text style={{ fontWeight: "bold" }}>{auth.lid}</Text>
              </Menu.Item> */}
              {/* <Menu.Item onPress={() => props.history.push("/logout")}>
                Logout
              </Menu.Item> */}
            </Menu>
            <img src={Logo} />
          </HStack>
          <HStack>
            <Text onClick={() => props.history.push("/logout")}>Logout</Text>
          </HStack>
          {/*}</Hidden>*/}
        </HStack>
      </VStack>
    </Nz1024>
  );
};

export default NzHeader;
