import {createStore, applyMiddleware} from 'redux';
import getRootReducer from '../reducers'

//DONT REMOVE THUNK.. WITHOUT THUNK ASYNC CALLS DONT WORK
import thunk from "redux-thunk";
import logger from "redux-logger";

export default function getStore(){
  let DEBUG = true;
  
  const middleware = [
    thunk,
    DEBUG && logger,
  ].filter(Boolean);

  const store = createStore(
      getRootReducer(),
      //undefined,
      //applyMiddleware(thunk,logger)
      //applyMiddleware(thunk) //WITHOUT LOGGER
      applyMiddleware(...middleware)   //LOGGER ENABLED ONLY ON DEVELOPMENT
  );

  return store;
}
