import React from "react";
import "./style.css";
import Navbar from "../../components/HomeComponents/NavBar/navbar";
import Banner from "../../components/HomeComponents/Banner/banner";
import Csr from "../../components/HomeComponents/Csr";
import Esg from "../../components/HomeComponents/Esg/esg";
import Features from "../../components/HomeComponents/Features/features";
import Footer from "../../components/HomeComponents/Footer/footer";

function Home(props) {
  return (
    <>
      <Navbar {...props} />
      <main>
        <Banner />
        <Features />
        <Csr />
        <Footer />
      </main>
    </>
  );
}

export default Home;
