let url = process.env.REACT_APP_OPT_SOCIETY_URL;
let nodzon_url = process.env.REACT_APP_NODZON_URL;

class Api {
  static login(om) {
    return fetch(url + "/lgn", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(om),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((err) => {
        throw err;
      });
  }

  static getCouponsAlloted(pickDate) {
    let fetchUrl;
    fetchUrl = nodzon_url + "/cdp?da=" + pickDate;
    return fetch(fetchUrl)
      .then((response) => response.json())
      .then((responseJson) => {
        responseJson.Items.sort(function (a, b) {
          return a.lai - b.lai;
        });
        return responseJson;
      })
      .catch((err) => {
        throw err;
      });
  }

  static getFSV(om) {
    let fetchUrl;
    fetchUrl = nodzon_url + "/fdp?id=21&li=" + om.li;
    if (om.la) {
      fetchUrl = fetchUrl + "&la=" + JSON.stringify(om.la);
    }
    if (om.desc) {
      fetchUrl = fetchUrl + "&desc=true";
    }
    return fetch(fetchUrl)
      .then((response) => response.json())
      .then((responseJson) => {
        // responseJson.Items.sort(function (a, b) {
        //   return b.po[0].cnt - a.po[0].cnt;
        // });
        // return responseJson;
        return {
          Items: responseJson.Items.filter((el) => {
            return el.n1 >= 150;
          }),
        };
      })
      .catch((err) => {
        throw err;
      });
  }

  static getLFSV(om, redeemedOnly) {
    let fetchUrl;
    fetchUrl = nodzon_url + "/fdl?id=21&li=" + om.li;
    if (om.la) {
      fetchUrl = fetchUrl + "&la=" + JSON.stringify(om.la);
    }
    if (om.desc) {
      fetchUrl = fetchUrl + "&desc=true";
    }
    if (redeemedOnly) {
      fetchUrl = fetchUrl + "&type=used";
    } else {
      fetchUrl = fetchUrl + "&type=hasCoupon";
    }
    return fetch(fetchUrl)
      .then((response) => response.json())
      .then((responseJson) => {
        // responseJson.Items.sort(function (a, b) {
        //   return b.po[0].cnt - a.po[0].cnt;
        // });
        return responseJson;
      })
      .catch((err) => {
        throw err;
      });
  }

  static getCoupons(id) {
    let fetchUrl;
    fetchUrl = nodzon_url + "/cdv?mo=" + id;
    return fetch(fetchUrl)
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((err) => {
        throw err;
      });
  }

  // static getTransactions(id) {
  //   let fetchUrl;
  //   fetchUrl = "http://192.168.1.6:2023/getSalesDetail4User?id=" + id;
  //   return fetch(fetchUrl)
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       return responseJson;
  //     })
  //     .catch((err) => {
  //       throw err;
  //     });
  // }

  static getTransactions(id) {
    const timeout = 1000;
    const controller = new AbortController();
    const oTimeout = setTimeout(() => controller.abort(), timeout);
    return fetch("http://192.168.1.6:2023/getSalesDetail4User?id=" + id, {
      signal: controller.signal,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((err) => {
        const timeout = 1000;
        const controller = new AbortController();
        const oTimeout = setTimeout(() => controller.abort(), timeout);
        return fetch("http://192.168.1.3:2023/getSalesDetail4User?id=" + id, {
          signal: controller.signal,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            return responseJson;
          })
          .catch((err) => {
            const timeout = 1000;
            const controller = new AbortController();
            const oTimeout = setTimeout(() => controller.abort(), timeout);
            return fetch(
              "http://192.168.1.2:2023/getSalesDetail4User?id=" + id,
              {
                signal: controller.signal,
              }
            )
              .then((response) => response.json())
              .then((responseJson) => {
                return responseJson;
              })
              .catch((err) => {
                const timeout = 1000;
                const controller = new AbortController();
                const oTimeout = setTimeout(() => controller.abort(), timeout);
                return fetch(
                  "http://192.168.1.4:2023/getSalesDetail4User?id=" + id,
                  {
                    signal: controller.signal,
                  }
                )
                  .then((response) => response.json())
                  .then((responseJson) => {
                    return responseJson;
                  })
                  .catch((err) => {
                    throw err;
                  })
                  .finally(() => {
                    clearTimeout(oTimeout);
                  });
              })
              .finally(() => {
                clearTimeout(oTimeout);
              });
          })
          .finally(() => {
            clearTimeout(oTimeout);
          });
      })
      .finally(() => {
        clearTimeout(oTimeout);
      });
  }

  //BELOW FUNCTION FOR REPEATED CALL HERE DIDN'T WORK
  // static getTransactions(id) {
  //   return this.getTransactionsMultipleIP(id, 1)
  //     .then((response) => {
  //       console.log(response);
  //       return response;
  //     })
  //     .catch(() => {
  //       this.getTransactionsMultipleIP(id, 2)
  //         .then((response) => {
  //           return response;
  //         })
  //         .catch(() => {
  //           this.getTransactionsMultipleIP(id, 3)
  //             .then((response) => {
  //               return response;
  //             })
  //             .catch(() => {
  //               this.getTransactionsMultipleIP(id, 4)
  //                 .then((response) => {
  //                   return response;
  //                 })
  //                 .catch(() => {
  //                   this.getTransactionsMultipleIP(id, 5)
  //                     .then((response) => {
  //                       return response;
  //                     })
  //                     .catch(() => {
  //                       this.getTransactionsMultipleIP(id, 6)
  //                         .then((response) => {
  //                           return response;
  //                         })
  //                         .catch(() => {
  //                           this.getTransactionsMultipleIP(id, 7)
  //                             .then((response) => {
  //                               return response;
  //                             })
  //                             .catch(() => {
  //                               this.getTransactionsMultipleIP(id, 8)
  //                                 .then((response) => {
  //                                   return response;
  //                                 })
  //                                 .catch(() => {
  //                                   this.getTransactionsMultipleIP(id, 9)
  //                                     .then((response) => {
  //                                       return response;
  //                                     })
  //                                     .catch((err) => {
  //                                       throw err;
  //                                     });
  //                                 });
  //                             });
  //                         });
  //                     });
  //                 });
  //             });
  //         });
  //     });
  // }

  // static getTransactionsMultipleIP(id, IP) {
  //   var promise = new Promise(function (resolve, reject) {
  //     const timeout = 1000;

  //     const controller = new AbortController();
  //     const oTimeout = setTimeout(() => controller.abort(), timeout);

  //     let fetchUrl;
  //     fetchUrl =
  //       "http://192.168.1." + IP + ":2023/getSalesDetail4User?id=" + id;
  //     return fetch(fetchUrl, { signal: controller.signal })
  //       .then((response) => response.json())
  //       .then((responseJson) => {
  //         resolve(responseJson);
  //       })
  //       .catch((err) => {
  //         // console.log("i m in catch:" + err);
  //         resolve({ Items: [] });
  //         //reject(err);
  //       })
  //       .finally(() => {
  //         // console.log("in finally block");
  //         clearTimeout(oTimeout);
  //       });
  //   });
  //   return promise;
  // }

  static redeemCoupons(om) {
    return fetch(nodzon_url + "/cdr", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(om),
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        } else {
          return response;
        }
      })
      .catch((err) => {
        throw err;
      });
  }
}

export default Api;
