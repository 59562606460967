import React from "react";
import { Center, Flex, HStack, Text } from "native-base";
import Logo from "./Logo.jpg";
import LogoInv from "./Logo.jpg";

const NzLogo = (props) => {
  if (props.variant === "inverted") {
    return (
      <Center h={"80px"}>
        <img src={LogoInv} />
      </Center>
    );
  } else {
    return (
      <Center h={"80px"}>
        <img src={Logo} />
      </Center>
    );
  }
};

export default NzLogo;
