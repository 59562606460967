import React from "react";
import { Box, Button, Center, Image, HStack, Text, VStack } from "native-base";

function Footer() {
  return (
    <VStack p="8">
      <HStack alignItems="center" justifyContent="space-between">
        <Text pl="2">
          &copy; 2023 Kairali Super a unit of Baywater Retail Pvt Ltd &reg;
        </Text>
        <Button
          variant="ghost"
          onPress={() => {
            window.location.href = "http://app.kairalisuper.com/policy.html";
          }}
        >
          Privacy Policy
        </Button>
      </HStack>
    </VStack>
  );
}
export default Footer;
