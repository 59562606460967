import React from "react";
import { Link } from "react-router-dom";
import "./style.css";
import Logo from "./Logo.jpg";
import Icon from "./icon.png";
import {
  Center,
  HamburgerIcon,
  Hidden,
  HStack,
  Image,
  Menu,
  Pressable,
  Text,
  VStack,
} from "native-base";

function Navbar(props) {
  return (
    <Center shadow={3} py={2}>
      <Center
        w={{
          base: "90%",
        }}
      >
        <VStack w={"100%"}>
          <HStack justifyContent="space-between" alignItems="center">
            <HStack space="2">
              <img src={Logo} height="45" />
            </HStack>
            <Hidden till="lg">
              <ul className="menu">
                <li>
                  <a href="#home">Home</a>
                </li>
                <li>
                  <a href="#why">Why Us?</a>
                </li>
                <li>
                  <a href="#about">About Us</a>
                </li>
                <li>
                  <a onClick={() => props.history.push("/login")}>Login</a>
                </li>
              </ul>
            </Hidden>
            <Hidden from="lg">
              <HStack alignItems="center" space="3">
                <Menu
                  w="190"
                  trigger={(triggerProps) => {
                    return (
                      <Pressable
                        accessibilityLabel="More options menu"
                        {...triggerProps}
                        alignSelf="center"
                      >
                        <HamburgerIcon />
                      </Pressable>
                    );
                  }}
                >
                  <Menu.Item onPress={() => props.history.push("/login")}>
                    Login
                  </Menu.Item>
                </Menu>
              </HStack>
            </Hidden>
          </HStack>
        </VStack>
      </Center>
    </Center>
  );
}

export default Navbar;
