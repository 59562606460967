import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import NzHeaderAdmin from "../components/NzComponents/NzHeaderAdmin";
import AAlignment from "../screens/AAlignment";
import DashBoard from "../screens/DashBoard";
import Home from "../screens/Home";
import Login from "../screens/Login";
import Logout from "../screens/Logout";
import UserLayout from "../screens/UserLayout";
import VDashBoard from "../screens/VDashBoard";
import FsvReport from "../screens/FsvReport";
import LFsvReport from "../screens/LFsvReport";
import RFsvReport from "../screens/RFsvReport";

const AdminLayout = (props) => (
  <>
    <NzHeaderAdmin {...props} />
    {props.children}
  </>
);

const AdminLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <AdminLayout {...props}>
          <Component {...props} />
        </AdminLayout>
      )}
    />
  );
};

const UserLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <UserLayout {...props}>
          <Component {...props} />
        </UserLayout>
      )}
    />
  );
};

export default () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" exact render={(props) => <Home {...props} />} />
      <Route path="/admin" exact render={(props) => <Login {...props} />} />
      <Route
        path="/aalign"
        exact
        render={(props) => <AAlignment {...props} />}
      />
      <Route path="/login" exact render={(props) => <Login {...props} />} />
      <Route path="/logout" exact render={(props) => <Logout {...props} />} />
      <UserLayoutRoute path="/dashboard" component={DashBoard} />
      <AdminLayoutRoute path="/vdashboard" component={VDashBoard} />
      <AdminLayoutRoute path="/fsvreport" component={FsvReport} />
      <AdminLayoutRoute path="/lfsvreport" component={LFsvReport} />
      <AdminLayoutRoute path="/rfsvreport" component={RFsvReport} />
    </Switch>
  </BrowserRouter>
);
