import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link as ReactLink } from "react-router-dom";
import { login, reEnter } from "../../actions";
import {
  Avatar,
  Box,
  Center,
  CircleIcon,
  Divider,
  Heading,
  Hidden,
  Image,
  Icon,
  Stack,
  StatusBar,
  Text,
  VStack,
  useBreakpointValue,
  HStack,
  Button,
  IconButton,
} from "native-base";

import { Eye, EyeSlash } from "../../components/Icons/NzIcons";
import { NzButton, NzInput } from "../../components/NzComponents";
import NzlogoHeader from "../../components/NzComponents/NzlogoHeader";

function LoginForm(props) {
  const width = useBreakpointValue({
    sm: "90%", //after 480px
    md: "80%", //after 768px
    lg: "40%", //after 992px
    xl: "30%", //after 1280px
  });

  const dispatch = useDispatch();
  const form1 = useRef(null);

  const auth = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);

  useEffect(() => {
    let user = localStorage.getItem("user");
    if (user) {
      dispatch(reEnter(JSON.parse(user)));
    }
  }, []);

  useEffect(() => {
    if (auth.isLoggedIn === true) {
      if (auth.c1 === "sp" || auth.c1 === "ag") {
        props.history.push("/dashboard");
      } else if (auth.c1 === "ad") {
        props.history.push("/admin");
      } else {
        props.history.push("/vdashboard");
      }
    }
  }, [auth]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    // data.id = users.Items[0].id;
    // data.p1 = users.Items[0].p1;

    console.log(data);
    dispatch(login(data));
    // props.history.push("/");
  };

  return (
    <VStack
      contentContainerStyle={{
        flexGrow: 1,
      }}
      style={{
        flex: 1,
      }}
    >
      <VStack
        flex="1"
        px="6"
        py="9"
        _light={{
          bg: "white",
        }}
        _dark={{
          bg: "coolGray.800",
        }}
        space="3"
        justifyContent="space-between"
        borderTopRightRadius={{
          base: "2xl",
          md: "xl",
        }}
        borderBottomRightRadius={{
          base: "0",
          md: "xl",
        }}
        borderTopLeftRadius={{
          base: "2xl",
          md: "0",
        }}
        shadow={4}
      >
        <VStack space="7">
          <Hidden till="md">
            <Text fontSize="lg" fontWeight="normal">
              Sign in to continue!
            </Text>
          </Hidden>
          <VStack>
            <form ref={form1} onSubmit={handleSubmit(onSubmit)}>
              <VStack space="3">
                <VStack
                  space={{
                    base: "7",
                    md: "4",
                  }}
                >
                  <VStack space="1">
                    <Controller
                      control={control}
                      defaultValue="admin@kairalisuper.com" //admin@optsociety.com
                      name="lid"
                      render={({ field }) => (
                        <NzInput {...field} placeholder="User ID" />
                      )}
                      rules={{
                        required: true,
                        pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                      }}
                    />
                    {errors.lid && errors.lid.type === "required" && (
                      <Box px={2} ml={"3%"}>
                        User ID is mandatory
                      </Box>
                    )}
                    {errors.lid && errors.lid.type === "pattern" && (
                      <Box px={2} ml={"3%"}>
                        User ID is not valid
                      </Box>
                    )}
                  </VStack>
                  <VStack space="1">
                    <Controller
                      control={control}
                      defaultValue="123"
                      name="p1"
                      render={({ field }) => (
                        <NzInput
                          {...field}
                          InputRightElement={
                            show ? (
                              <Eye
                                mr="2"
                                size="4"
                                onClick={() => setShow(!show)}
                              />
                            ) : (
                              <EyeSlash
                                mr="2"
                                size="4"
                                onClick={() => setShow(!show)}
                              />
                            )
                          }
                          placeholder="Password"
                          type={show ? "text" : "password"}
                          autoComplete={false}
                        />
                      )}
                      rules={{
                        required: true,
                      }}
                    />
                    {errors.p1 && errors.p1.type === "required" && (
                      <Box px={2} ml={"3%"}>
                        Password is mandatory
                      </Box>
                    )}
                    {auth.status === "InvalidUser" && (
                      <Box px={2} ml={"3%"}>
                        Couldn't find your Optshine Account
                      </Box>
                    )}
                    {auth.status === "WrongPWD" && (
                      <Box px={2} ml={"3%"}>
                        Wrong Password. Try again or click "Forgot Password" to
                        reset it.
                      </Box>
                    )}
                    {auth.status === "networkerror" && (
                      <Box px={2} ml={"3%"}>
                        <Text color="pink.600">
                          Please check your internet connection and Try again.
                        </Text>
                      </Box>
                    )}
                  </VStack>
                </VStack>
                <NzButton
                  my={5}
                  // onPress={(e) => {
                  //   props.history.push("/dashBoard");
                  // }}
                  onPress={() =>
                    form1.current.dispatchEvent(
                      new Event("submit", { bubbles: true, cancelable: true })
                    )
                  }
                >
                  LOGIN
                </NzButton>
              </VStack>
            </form>
          </VStack>
        </VStack>
      </VStack>
    </VStack>
  );
}

function Login(props) {
  const width = useBreakpointValue({
    sm: "90%", //after 480px
    md: "80%", //after 768px
    lg: "40%", //after 992px
    xl: "30%", //after 1280px
  });

  const bgColor = useBreakpointValue({
    base: "primary.900",
    sm: "primary.900", //after 480px
    md: "transparent", //after 768px
    lg: "transparent", //after 992px
    xl: "transparent", //after 1280px
  });

  console.log(width);

  return (
    <>
      <StatusBar
        translucent
        backgroundColor="transparent"
        barStyle="light-content"
      />
      <Box
        safeAreaTop
        _light={{
          bg: "primary.900",
        }}
        _dark={{
          bg: "coolGray.900",
        }}
      />
      <Center my="auto" bg={bgColor} h="100vh">
        <Stack
          flexDirection={{
            base: "column",
            md: "row",
          }}
          w="100%"
          maxW={{
            md: "768px",
          }}
          flex={{
            base: "1",
            md: "none",
          }}
        >
          <Hidden from="md">
            <VStack px="4" mt="4" mb="5" space="9">
              <VStack space="5">
                <NzlogoHeader variant="inverted" />
                <Text fontSize="3xl" fontWeight="bold" color="coolGray.50">
                  Welcome back!
                </Text>
              </VStack>
            </VStack>
          </Hidden>
          <Hidden till="md">
            <Center
              flex="1"
              bg="primary.700"
              borderTopLeftRadius={{
                base: "0",
                md: "xl",
              }}
              borderBottomLeftRadius={{
                base: "0",
                md: "xl",
              }}
            >
              <NzlogoHeader variant="inverted" />
            </Center>
          </Hidden>
          <LoginForm {...props} />
        </Stack>
      </Center>
    </>
  );
}

export default Login;
