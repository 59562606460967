import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";

import {
  Button,
  Box,
  CloseIcon,
  Flex,
  HStack,
  Hidden,
  Pressable,
  VStack,
  Text,
  Center,
} from "native-base";
import NzHeader from "../../components/NzComponents/NzHeader";

import {
  Nz1024,
  NzBannerCard,
  NzButton,
  NzFlexWrap,
  NzHeading,
  NzLabel,
} from "../../components/NzComponents";
import {
  DownArrow2,
  BadgeNew,
  BadgeVerified,
} from "../../components/Icons/NzIcons";
import { getCouponsAlloted } from "../../actions";

import DateTimePicker from "react-date-picker";
import { lastDayMonthString, lastDayThisMonthString } from "../../js/util";

const VDashBoard = (props) => {
  const auth = useSelector((state) => state.auth);
  const coupons = useSelector((state) => state.coupons);
  const [pickDate, onChange] = useState(new Date());
  const [calenderOpen, setCalenderOpen] = useState(false);

  const dispatch = useDispatch();
  const uploadInput = useRef(null);
  const [uploading, setUploading] = useState(false);
  const uploadStudentInput = useRef(null);

  useEffect(() => {
    if (auth.c1 === "va") {
      // dispatch(getBuild({ id: "studentUpload", v2: auth.id }));
    }
  }, []);

  return (
    <>
      <Nz1024 bg="#efefe8">
        <NzBannerCard>
          <NzHeading>Day Wise FSV Report</NzHeading>
        </NzBannerCard>
        <NzBannerCard>
          <Flex pb={calenderOpen ? 80 : 8} direction="row">
            <DateTimePicker
              // disabled={pickNdrop.a === true}
              clearIcon={null}
              onCalendarClose={() => {
                setCalenderOpen(false);
              }}
              onCalendarOpen={() => {
                setCalenderOpen(true);
              }}
              onChange={(text) => {
                onChange(text);
                // setPickNdrop({ ...pickNdrop, d: text });
              }}
              value={pickDate}
              format="dd-MMM"
              maxDate={new Date()}
            />
            &nbsp;&nbsp;
            <NzButton
              variant="outline"
              onPress={(e) => {
                //props.history.push("/studentList");
                dispatch(getCouponsAlloted(pickDate));
              }}
            >
              Show Free Shopping Voucher Allocated
            </NzButton>
          </Flex>

          <NzFlexWrap>
            {coupons.redeemed && (
              <VStack
                bg="#efefe8"
                rounded="lg"
                p="4"
                m="2"
                w={{ base: "100%" /*, lg: "auto" */ }}
              >
                <NzLabel size="lg" color="green.700">
                  Coupon Redeemed
                </NzLabel>
              </VStack>
            )}
            {coupons.Items.length > 0 && (
              <NzLabel size="lg" color="green.700">
                Total : {coupons.Items.length}
              </NzLabel>
            )}
            {coupons.Items.map((el) => {
              return (
                <VStack
                  bg="#efefe8"
                  rounded="lg"
                  p="4"
                  m="2"
                  w={{ base: "100%" /*, lg: "auto" */ }}
                >
                  {/* <NzLabel size="md">{JSON.stringify(el)}</NzLabel> */}
                  {el.po ? (
                    <VStack>
                      <NzLabel size="lg">Mobile: {el.lid}</NzLabel>
                      <NzLabel size="sm">Last Invoice: {el.lai}</NzLabel>
                      <HStack
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <NzLabel size="md">
                          Current Points: {el.po[lastDayThisMonthString()]}
                        </NzLabel>
                        {/* <NzLabel size="sm">
                          Points Expiry Date: {lastDayMonthString(pickDate)}
                        </NzLabel> */}
                      </HStack>
                    </VStack>
                  ) : (
                    <NzLabel size="md">NO POINTS YET</NzLabel>
                  )}
                  {el.cu ? (
                    <>
                      <VStack>
                        <NzLabel size="md"> </NzLabel>
                        <NzLabel size="xl" color="orange.700">
                          🎉 {el.cu[lastDayMonthString(pickDate)].fsv} Points
                          Coupon
                        </NzLabel>
                        <NzLabel size="md">
                          Coupon Expiry Date: {lastDayMonthString(pickDate)}
                        </NzLabel>
                      </VStack>
                      {/* <HStack direction="row-reverse">
                        <NzButton
                          bgColor="green.600"
                          mx="4"
                          onPress={(e) => {
                            const confirmBox = window.confirm(
                              "Sure to REDEEM COUPON?"
                            );
                            // if (confirmBox === true) {
                            //   dispatch(redeemCoupons(el.lid));
                            // }
                          }}
                        >
                          REDEEM
                        </NzButton>
                      </HStack> */}
                    </>
                  ) : (
                    <NzLabel size="md">{"\n"}NO FSV TO REDEEM</NzLabel>
                  )}
                </VStack>
              );
            })}
          </NzFlexWrap>
        </NzBannerCard>
      </Nz1024>
    </>
  );
};

export default VDashBoard;
