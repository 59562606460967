import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Flex, HStack, Text, VStack } from "native-base";
import { useDispatch, useSelector } from "react-redux";

import { getCoupons, getTransactions, redeemCoupons } from "../../actions";

import {
  Nz1024,
  NzBannerCard,
  NzButton,
  NzFlexWrap,
  NzHeading,
  NzInput,
  NzLabel,
} from "../../components/NzComponents";

import { lastDayThisMonthString } from "../../js/util";

const redeemPrice = 0.75;

const DashBoard = (props) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const coupons = useSelector((state) => state.coupons);
  const transactions = useSelector((state) => state.transactions);
  const [search, setSearch] = useState("");
  const [showFsvHistory, setShowFsvHistory] = useState(false);
  const [showTransHistory, setShowTransHistory] = useState(false);
  const [redeemValue, setRedeemValue] = useState(0);
  const [invoice, setInvoice] = useState("");
  const [msgPhoneIncorrect, setMsgPhoneIncorrect] = useState("");

  let total = 0;
  for (let i = 0; i < transactions.Items.length; i++) {
    total += transactions.Items[i].BillAmt;
  }

  return (
    <Nz1024 bg="#efefe8" pb="40">
      <NzBannerCard>
        <NzHeading>
          {auth.c1 === "sp" ? "FSV Redeem Dashboard" : "Agency Dashboard"}
        </NzHeading>
      </NzBannerCard>
      <NzBannerCard>
        <NzLabel>Customers Phone Number : </NzLabel>
        <NzInput
          maxLength="10"
          placeholder="Phone Number *"
          onChange={(event) => {
            setMsgPhoneIncorrect("");
            setSearch(event.target.value);
          }}
        />

        {msgPhoneIncorrect === "incorrect" && (
          <Box px={1}>
            <Text bold color="red.500">
              Phone Number is Not Correct
            </Text>
          </Box>
        )}
        <NzFlexWrap>
          <NzButton
            variant="outline"
            onPress={() => {
              if (
                search !== "" &&
                search.length === 10 &&
                /^\d+$/.test(search) //number check
              ) {
                setMsgPhoneIncorrect("");
                dispatch(getCoupons(search));
                dispatch(getTransactions(search));
                setRedeemValue(0);
                setInvoice("");
                setShowFsvHistory(false);
                setShowTransHistory(false);
              } else {
                setMsgPhoneIncorrect("incorrect");
              }
            }}
          >
            &nbsp;&nbsp;SEARCH&nbsp;&nbsp;
          </NzButton>
        </NzFlexWrap>

        <NzFlexWrap>
          {coupons.redeemed && (
            <VStack
              bg="#efefe8"
              rounded="lg"
              p="4"
              m="2"
              w={{ base: "100%" /*, lg: "auto" */ }}
            >
              <NzLabel size="lg" color="green.700">
                Coupon Redeemed
              </NzLabel>
            </VStack>
          )}
          {coupons.hasErrored && (
            <VStack
              bg="#efefe8"
              rounded="lg"
              p="4"
              m="2"
              w={{ base: "100%" /*, lg: "auto" */ }}
            >
              <NzLabel size="lg" color="red.700">
                FAILED FSV Redeem - please try again
              </NzLabel>
            </VStack>
          )}
          {coupons.Items.map((el) => {
            let cu = {};
            if (el.cu) {
              for (var key in el.cu) {
                cu.exp = key;
                cu.pnt = el.cu[key];
                // console.log(el.cu[key]);
              }
            }
            return (
              <>
                <VStack
                  bg="#efefe8"
                  rounded="lg"
                  p="4"
                  m="2"
                  w={{ base: "100%" /*, lg: "auto" */ }}
                >
                  {/* <NzLabel size="md">{JSON.stringify(el)}</NzLabel> */}
                  {el.po ? (
                    <VStack>
                      <NzLabel size="lg">
                        Current Points: {el.po[lastDayThisMonthString()]}
                      </NzLabel>
                      {/* <NzLabel size="sm">
                        Points Expiry Date: {lastDayThisMonthString()}
                      </NzLabel> */}
                    </VStack>
                  ) : (
                    <NzLabel size="md">NO POINTS YET</NzLabel>
                  )}
                  <NzLabel size="md"> </NzLabel>
                  {cu.pnt && el.n2 > 0 ? (
                    <>
                      <VStack>
                        <NzLabel size="md"> </NzLabel>
                        <NzLabel size="xl" color="orange.700">
                          🎉 {el.n2.toString()} FSV
                        </NzLabel>
                        <NzLabel pt="4" size="md">
                          FSV Expiry Date: {cu.exp}
                        </NzLabel>
                        <NzLabel pt="4" size="lg">
                          Max Redeem Amount = Rs.
                          {el.n2 * redeemPrice}
                        </NzLabel>
                      </VStack>
                      <HStack pt="4" direction="row-reverse">
                        <NzButton
                          bgColor="green.600"
                          mx="4"
                          onPress={(e) => {
                            if (isNaN(redeemValue)) {
                              alert("Invalid FSV Count");
                            } else {
                              if (
                                parseInt(redeemValue) <= parseInt(el.n2) &&
                                parseInt(redeemValue) > 0
                              ) {
                                const confirmBox = window.confirm(
                                  "Sure to REDEEM FSV?"
                                );
                                if (confirmBox === true) {
                                  dispatch(
                                    redeemCoupons({
                                      lid: el.lid,
                                      exp: cu.exp,
                                      amt: redeemValue,
                                      iv: invoice,
                                    })
                                  );
                                }
                              } else {
                                alert("FSV value is not correct");
                              }
                            }
                          }}
                        >
                          REDEEM
                        </NzButton>
                        <NzLabel size="xl" pl="8" pr="8" color="green.700">
                          Rs.{redeemValue * redeemPrice}
                        </NzLabel>
                        <NzLabel size="md" pl="8" pr="8" color="green.700">
                          Redeem Amount:
                        </NzLabel>
                        <NzInput
                          maxLength="8"
                          pl="8"
                          pr="8"
                          placeholder="Invoice Number"
                          onChange={(event) => {
                            setInvoice(event.target.value);
                          }}
                        />
                        <NzInput
                          maxLength="3"
                          pl="8"
                          pr="8"
                          placeholder="FSV to Redeem *"
                          onChange={(event) => {
                            setRedeemValue(event.target.value);
                          }}
                        />
                      </HStack>
                    </>
                  ) : cu.pnt && el.n2 == 0 ? (
                    <>
                      <NzLabel size="lg" color="orange.400">
                        {cu.pnt.fsv.toString()} FSV REDEEMED for the month{" "}
                      </NzLabel>
                      <NzLabel size="md">
                        Redeemed {cu.pnt.usg.toString()}
                      </NzLabel>
                    </>
                  ) : (
                    <NzLabel size="md">{"\n"}NO FSV TO REDEEM</NzLabel>
                  )}
                </VStack>
                <NzFlexWrap>
                  <NzButton
                    variant="ghost"
                    _text={{ color: "#7e22ce", fontWeight: "bold" }}
                    onPress={() => {
                      setShowTransHistory(!showTransHistory);
                      setShowFsvHistory(false);
                    }}
                  >
                    &nbsp;&nbsp;Transactions History&nbsp;&nbsp;
                  </NzButton>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <NzButton
                    variant="ghost"
                    _text={{ color: "#7e22ce", fontWeight: "bold" }}
                    onPress={() => {
                      setShowFsvHistory(!showFsvHistory);
                      setShowTransHistory(false);
                    }}
                  >
                    &nbsp;&nbsp;FSV History&nbsp;&nbsp;
                  </NzButton>
                </NzFlexWrap>
                {showFsvHistory && (
                  <VStack
                    bg="#efefe8"
                    rounded="lg"
                    p="4"
                    m="2"
                    w={{ base: "100%" /*, lg: "auto" */ }}
                  >
                    <VStack>
                      <NzLabel size="lg">FSV History</NzLabel>
                    </VStack>
                    {el.cu_all ? (
                      <>
                        {el.cu_all.map((el2) => {
                          for (var key in el2) {
                            // cu.exp = key;
                            // cu.pnt = el.cu[key];
                            // console.log(el.cu[key]);
                            if (el2[key].fsv > 149)
                              return (
                                <VStack>
                                  <HStack>
                                    <NzLabel pt="4" size="md">
                                      {el2[key].fsv} FSV - Expiry Date: {key}
                                    </NzLabel>
                                  </HStack>
                                  <NzLabel
                                    size="md"
                                    color={
                                      el2[key].usg === ""
                                        ? "orange.400"
                                        : "blue.400"
                                    }
                                  >
                                    {el2[key].usg === ""
                                      ? "Redeemed " + el2[key].usg
                                      : "Got expired - unredeemed"}
                                  </NzLabel>
                                </VStack>
                              );
                          }
                        })}
                      </>
                    ) : (
                      <NzLabel size="md">------------</NzLabel>
                    )}
                  </VStack>
                )}
                {showTransHistory && (
                  <VStack
                    bg="#efefe8"
                    rounded="lg"
                    p="4"
                    m="2"
                    w={{ base: "100%" /*, lg: "auto" */ }}
                  >
                    <VStack>
                      <NzLabel size="lg">
                        Last Month Transactions History
                      </NzLabel>
                    </VStack>
                    {transactions.Items.map((el) => {
                      return (
                        <VStack>
                          <HStack>
                            <NzLabel pt="4" size="md">
                              {new Date(el.InvDate).toLocaleDateString()}{" "}
                              {/* {new Date(el.billtime).toLocaleTimeString()} - Rs.{" "} */}
                              Rs. {el.BillAmt}
                            </NzLabel>
                          </HStack>
                        </VStack>
                      );
                    })}
                    <VStack>
                      <NzLabel pt="4" size="md">
                        Last Month Total Purchase : Rs.{total}
                      </NzLabel>
                    </VStack>
                  </VStack>
                )}
              </>
            );
          })}
        </NzFlexWrap>
      </NzBannerCard>
    </Nz1024>
  );
};

export default DashBoard;
