import { Center, Flex, Heading, Hidden, Text, VStack } from "native-base";
import React from "react";
import { NzButton } from "../../NzComponents";
import Img from "./shop.png";
import { DownArrow } from "../../Icons/NzIcons";

function Banner() {
  return (
    <>
      <Center bg="#00000005" my="auto" h="50vh" overflowX="hidden">
        <Flex
          flexDirection={{
            base: "column-reverse",
            md: "row",
          }}
          flexGrow="1"
          alignItems="center"
          justify="space-around"
          w="100vw"
        >
          <VStack
            w={{
              base: "90%",
              md: "50%",
            }}
          >
            <Heading size="2xl" color="primary.1000">
              Kairali Super a unit of Baywater Retail Pvt Ltd
            </Heading>
            <Heading size="md" color="primary.700" mb="8" mt="2">
              Shop for Groceries, Vegetables, Fruits, Dairy products and many
              more from your own Kairali Super Store, Palarivattom
            </Heading>
            <NzButton href="https://play.google.com/store/apps/details?id=com.kairalisuper">
              Download KairaliSuper App on Android
            </NzButton>
          </VStack>
          <VStack
            w={{
              base: "85%",
              md: "40%",
            }}
            h="auto"
          >
            <img src={Img} />
          </VStack>
        </Flex>
      </Center>
    </>
  );
}
export default Banner;
