import React from "react";
import { Box, Center, Flex, Heading, Text, VStack, Image } from "native-base";
import "./features.css";
import Img from "./checked.png";
import Img2 from "./equalizer.png";
import Img3 from "./wallet.png";
import Img4 from "./piggybank.png";

function Features() {
  return (
    <section id="why">
      <Center bg="primary.1000" py="12">
        <VStack
          w={{
            base: "90%",
            md: "80%",
          }}
          alignItems="center"
          space="6"
          my="4"
        >
          <Heading color="white" textAlign="center">
            Why Us?
          </Heading>
          <Flex flexDirection="row" wrap="wrap" justifyContent="space-around">
            <Box bg="white" w="300px" minH="320" m="4">
              <VStack my="4" space="5" alignItems="center">
                <Box px="7" pt="4">
                  <Image source={Img} size="md" />
                </Box>
                <Text bold fontSize="lg" color="primary.1000" px="4">
                  Product Quality
                </Text>
                <Text px="7" pb="4" textAlign="center">
                  Improving product quality is one of the most important things
                  for achieving trust and faith of our Customers.
                </Text>
              </VStack>
            </Box>
            <Box bg="white" w="300px" minH="320" m="4">
              <VStack my="4" space="5" alignItems="center">
                <Box px="7" pt="4">
                  <Image source={Img2} size="md" />
                </Box>
                <Text bold fontSize="lg" color="primary.1000" px="4">
                  Application Peformance
                </Text>
                <Text px="7" pb="4" textAlign="center">
                  kairalisuper app is designed to make purchases quickly by
                  customer and the application is designed so
                </Text>
              </VStack>
            </Box>
            <Box bg="white" w="300px" minH="320" m="4">
              <VStack my="4" space="5" alignItems="center">
                <Box px="7" pt="4">
                  <Image source={Img3} size="md" />
                </Box>
                <Text bold fontSize="lg" color="primary.1000" px="4">
                  Purpose Specific Payments
                </Text>
                <Text px="7" pb="4" textAlign="center">
                  Payments are at delivery, ensuring that you pay only if you
                  need and after verification of delivery
                </Text>
              </VStack>
            </Box>
            <Box bg="white" w="300px" minH="320" m="4">
              <VStack my="4" space="5" alignItems="center">
                <Box px="7" pt="4">
                  <Image source={Img4} size="md" />
                </Box>
                <Text bold fontSize="lg" color="primary.1000" px="4">
                  Client Satisfaction
                </Text>
                <Text px="7" pb="4" textAlign="center">
                  Kairali Super gives much attention to client Satisfaction, as
                  we understand that if our Clients are satified, then only are
                  we.
                </Text>
              </VStack>
            </Box>
          </Flex>
        </VStack>
      </Center>
    </section>
  );
}
export default Features;
